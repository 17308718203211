import {
    MOBILE_CLIENT_WIDTH,
    PAGE_HEADER,
    STORAGE,
    PERMISSION,
    CACHE_KEY,
    GROUP_ROLE,
    SYSTEM_ROLE,
    SYSTEM_ROLE_NAME,
    UNSORTED_ID,
    HKY_PRIVEW_URL,
    HKY_DOWLOAD_URL,
    SHOW_GROUP_ROLE,
    FORM_EXPERIMENT_FIELD,
    FORM_EXPERIMENT_FIELD_TEMPLATE
} from '../constants'
import {mapActions, mapState,mapMutations} from 'vuex'
import {SetStorage,GetStorage,RemoveStorage} from '../util/cache'
import { toLogin,outLogin } from '../util/login'
import { SET_USER,SET_TOKEN,SET_ROLE,SET_PERMISSION } from '../store/types'
import {OutLoginPlatform} from '../service/user'

export const AppMixins = {
    data(){
        return {
            isMobileClient: false,
            PAGE_HEADER,
            STORAGE,
            PERMISSION,
            CACHE_KEY,
            GROUP_ROLE,
            SYSTEM_ROLE,
            SYSTEM_ROLE_NAME,
            UNSORTED_ID,
            HKY_PRIVEW_URL,
            HKY_DOWLOAD_URL,
            SHOW_GROUP_ROLE,
            FORM_EXPERIMENT_FIELD,
            FORM_EXPERIMENT_FIELD_TEMPLATE,
            ignoreRoutes:['Index','LoadLogin',"NoPermission","Search","TechnicalSummary","EmergingTechnologies"],
            permissionPage:{
                UserManager:{
                    operator:'AND',
                    permissions:[PERMISSION.CONSOLE_MANAGER,PERMISSION.USER_MANAGER]
                },
                RoleManager:{
                    operator:'AND',
                    permissions:[PERMISSION.CONSOLE_MANAGER,PERMISSION.ROLE_MANAGER]
                }
            }
        }
    },
    created(){
        this.isMobileClient = document.body.clientWidth <= MOBILE_CLIENT_WIDTH
    },
    computed: {
        ...mapState([
            'user','roles','permissions'
        ]),
    },
    methods:{
        SetStorage,GetStorage,RemoveStorage,toLogin,
        ...mapMutations([
            SET_USER,SET_TOKEN,SET_ROLE,SET_PERMISSION
        ]),
        layoutMinHeight() {
            return this.isMobileClient?(document.documentElement.clientHeight - 70 + 'px'):(document.documentElement.clientHeight - 48 - 8 - 60 + 'px')
        },
        APIParse(e,interceptCode = []){
            if(interceptCode.includes(e.code)){
                return e.data;
            }
            if(e.code == 0){
                return e.data;
            }else if(e.code == -400){
                toLogin()
            }else if(e.code == -403){
                //跳转封禁页面
            }else if(e.code == -404){
                //您不在当前项目内
                this.$router.push("/manager/group")
                return;
            }else if(e.code == -405){
                //您不在当前项目内

            }
            throw new Error(e.msg);
        },
        judge(data,defaultValue){
            return data || defaultValue
        },
        hasRole(roles,operator = 'OR'){
            let status = false;
            roles = roles || [];
            let sysRoles = (this.GetStorage(this.STORAGE.ROLE) || []);
            if(operator == 'OR'){
                for(let i = 0; i < roles.length; i++){
                    if((sysRoles.includes(roles[i]))){
                        status = true;
                        return status;
                    }
                }
            }else{
                for(let i = 0; i < roles.length; i++){
                    if((!sysRoles.includes(roles[i]))){
                        return status;
                    }
                }
                status = true;
            }
            return status;
        },
        hasPermission(permissions,operator = 'OR'){
            let status = false;
            permissions = permissions || [];
            let sysPermissions = (this.GetStorage(this.STORAGE.PERMISSION) || []);
            if(operator == 'OR'){
                for(let i = 0; i < permissions.length; i++){
                    if((sysPermissions.includes(permissions[i]))){
                        status = true;
                        return status;
                    }
                }
            }else{
                for(let i = 0; i < permissions.length; i++){
                    if((!sysPermissions.includes(permissions[i]))){
                        return status;
                    }
                }
                status = true;
            }
            return status;
        },
        setUser(userInfo,otherInfo){
            this[SET_USER](userInfo);
            this.SetStorage(this.STORAGE.USER,userInfo);
            if(otherInfo){
                this[SET_TOKEN](otherInfo.tokenInfo);
                this[SET_PERMISSION](otherInfo.permissionList);
                this[SET_ROLE](otherInfo.roleList);
                this.SetStorage(this.STORAGE.TOKEN,otherInfo.tokenInfo)
                this.SetStorage(this.STORAGE.ROLE,otherInfo.roleList || [])
                this.SetStorage(this.STORAGE.PERMISSION,otherInfo.permissionList || [])
            }
        },
        outLogin(){
            this[SET_USER](null);
            this[SET_TOKEN](null);
            this[SET_PERMISSION](null);
            this[SET_ROLE](null);
            this.RemoveStorage(this.STORAGE.USER)
            this.RemoveStorage(this.STORAGE.TOKEN)
            this.RemoveStorage(this.STORAGE.PERMISSION)
            this.RemoveStorage(this.STORAGE.ROLE)
            OutLoginPlatform();
            outLogin();
        },
        toNoPermission(){
            location.href = '/403'
        },
        setDocumentTitle (title) {
            document.title = title
        },
        downBlobToPage(base64File, fileName) {
            var blob = this.Base64toBlob(base64File);
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.setAttribute('download', fileName);
            a.target = 'downloadIframe';
            a.click();
            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, fileName);
            }
        },
        //将base64Data转换成blob对象
        Base64toBlob(base64Data) {
            var contentType = '';
            var sliceSize = 512;
            var byteCharacters = window.atob(base64Data)
            var byteArrays = []
            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize)
                var byteNumbers = new Array(slice.length)
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i)
                }
                var byteArray = new Uint8Array(byteNumbers)
                byteArrays.push(byteArray)
            }
            var blob = new Blob(byteArrays, {type: contentType})
            return blob
        }
    },
    beforeRouteEnter (to, from, next){
        next(vm => {
            if(vm.ignoreRoutes.includes(to.name) || vm.user){
                let needPermissions = vm.permissionPage[to.name] || {}
                if(!vm.hasPermission(needPermissions.permissions || [],needPermissions.operator || 'AND')){
                    //无权限访问当前页面
                    vm.toNoPermission();
                }

            }else{
                vm.toLogin();
            }
        });
    }
}